<template>
  <n-form
    ref="form"
    :label-width="standalone ? 80 : 'auto'"
    :model="payoutProfile"
    :label-placement="standalone ? 'top' : 'left'"
    :require-mark-placement="standalone ? 'right' : 'right-hanging'"
  >
    <n-divider title-placement="left">
      {{ t("profile.payoutMethodsInformation.form.address.title") }}
    </n-divider>
    <n-form-item v-if="addressOptions?.length > 0" :rule="baseRule" path="address.id">
      <n-select
        v-model:value="payoutProfile.address.id"
        :options="addressOptions"
        @update:value="handleUpdateAddressSelection"
    /></n-form-item>
    <n-empty
      v-else
      :description="
        t(
          'profile.payoutMethodsInformation.form.address.notificationWhenNoAddressRegistered'
        )
      "
    >
      <template v-if="standalone" #extra>
        <n-button size="small" @click="goToProfilePanel">
          {{
            t(
              "profile.payoutMethodsInformation.form.address.buttonTextWhenNoAddressRegistered"
            )
          }}</n-button
        >
      </template>
    </n-empty>
    <n-divider title-placement="left">{{
      t("profile.payoutMethodsInformation.form.paymentMethods.title")
    }}</n-divider>
    <n-form-item :rule="baseRule" label="Type" path="paymentMethods[0].type">
      <n-select
        v-model:value="payoutProfile.paymentMethods[0].type"
        :options="[
          {
            label: t(
              'profile.payoutMethodsInformation.form.paymentMethods.type.options.BANK_TRANSFER'
            ),
            value: 'BANK_TRANSFER',
          },
        ]"
        disabled
      /> </n-form-item
    ><n-form-item
      v-if="standalone"
      :rule="baseRule"
      :label="t('Profile.ContactInformation.Form.Email.label')"
      path="paymentMethods[0].email"
    >
      <n-input
        v-model:value="payoutProfile.paymentMethods[0].email"
        :placeholder="t('Profile.ContactInformation.Form.Email.placeholder')"
        disabled
      />
    </n-form-item>
    <n-form-item
      :rule="{ ...baseRule, type: 'number' }"
      :label="
        t('profile.payoutMethodsInformation.form.paymentMethods.routingNumber.label')
      "
      path="paymentMethods[0].rountingNumber"
    >
      <n-input-number
        v-model:value="payoutProfile.paymentMethods[0].rountingNumber"
        :placeholder="
          t(
            'profile.payoutMethodsInformation.form.paymentMethods.routingNumber.placeholder'
          )
        "
        :show-button="false"
        :min="100000000"
        :max="999999999"
        :keyboard="{ ArrowUp: false, ArrowDown: false }"
      />
    </n-form-item>
    <n-form-item
      :rule="{ ...baseRule, type: 'number' }"
      :label="
        t('profile.payoutMethodsInformation.form.paymentMethods.accountNumber.label')
      "
      path="paymentMethods[0].accountNumber"
    >
      <n-input-number
        v-model:value="payoutProfile.paymentMethods[0].accountNumber"
        :placeholder="
          t(
            'profile.payoutMethodsInformation.form.paymentMethods.accountNumber.placeholder'
          )
        "
        :show-button="false"
        :min="10000"
        :max="99999999999999999"
        :keyboard="{ ArrowUp: false, ArrowDown: false }"
      />
    </n-form-item>
    <n-form-item
      :rule="baseRule"
      :label="
        t('profile.payoutMethodsInformation.form.paymentMethods.accountHolder.label')
      "
      path="paymentMethods[0].nameAccountHolder"
    >
      <n-input
        v-model:value="payoutProfile.paymentMethods[0].nameAccountHolder"
        :placeholder="
          t(
            'profile.payoutMethodsInformation.form.paymentMethods.accountHolder.placeholder'
          )
        "
      />
    </n-form-item>
  </n-form>
</template>
<script>
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import responsive from "@/mixins/responsive";
import { useI18n } from "vue-i18n";
import {
  NDivider,
  NForm,
  NFormItem,
  NInput,
  NSelect,
  NInputNumber,
  NEmpty,
  NButton,
  useDialog,
} from "naive-ui";

export default defineComponent({
  name: "PayoutProfileUpdate",
  mixins: [responsive],
  emits: ["update:value", "canContinue", "update:formRef"],
  components: {
    NDivider,
    NForm,
    NFormItem,
    NInput,
    NSelect,
    NInputNumber,
    NEmpty,
    NButton,
  },
  props: {
    value: Object,
    standalone: { type: Boolean, default: false },
    formRef: { type: Object, default: null },
  },
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const dialog = useDialog();
    const store = useStore();
    const router = useRouter();
    const currentUser = computed(() => store.state.user.userFromDB);
    const formRef = ref(props.formRef);
    const addressOptionsRef = computed(() => {
      const options = currentUser.value?.addresses.map((address) => {
        return {
          value: address.id,
          label: `${address.locationInfo.address}, ${address.locationInfo.municipality}, ${address.locationInfo.region}`,
        };
      });
      return options;
    });
    const payoutProfileRef = ref(
      props.value || {
        externalId: null,
        address: {
          street: null,
          city: null,
          postalCode: null,
        },
        paymentMethods: [
          {
            type: "BANK_TRANSFER",
            active: true,
            email: currentUser.value?.email,
            rountingNumber: null,
            accountNumber: null,
            nameAccountHolder: null,
          },
        ],
      }
    );
    onMounted(() => {});
    watch(
      payoutProfileRef,
      () => {
        emit("update:value", payoutProfileRef.value);
        emit(
          "canContinue",
          computed(() => {
            if (!payoutProfileRef.value.address.street) {
              return false;
            }
            if (!payoutProfileRef.value.address.city) {
              return false;
            }
            if (!payoutProfileRef.value.address.postalCode) {
              return false;
            }
            if (!payoutProfileRef.value.paymentMethods[0].rountingNumber) {
              return false;
            }
            if (!payoutProfileRef.value.paymentMethods[0].accountNumber) {
              return false;
            }
            if (!payoutProfileRef.value.paymentMethods[0].nameAccountHolder) {
              return false;
            }
            return true;
          }).value
        );
      },
      { deep: true }
    );

    watch(
      formRef,
      (newFormRef) => {
        emit("update:formRef", newFormRef);
      },
      { deep: true }
    );
    watch(
      () => props.value,
      (newValue) => {
        if (newValue) {
          payoutProfileRef.value = newValue;
        }
      }
    );

    const handleUpdateAddressSelection = (newSelection) => {
      if (newSelection) {
        const addressInfo = currentUser.value.addresses.find(
          (address) => address.id === newSelection
        );
        payoutProfileRef.value.address = {
          street: addressInfo.locationInfo.address,
          city: addressInfo.locationInfo.municipality,
          postalCode: addressInfo.locationInfo.postalCode,
          id: newSelection,
        };
      } else {
        payoutProfileRef.value.address = {
          street: null,
          city: null,
          postalCode: null,
          id: null,
        };
      }
    };

    return {
      t,
      form: formRef,
      payoutProfile: payoutProfileRef,
      addressOptions: addressOptionsRef,
      baseRule: {
        required: true,
        message: t("commons.form.validation.required"),
        trigger: ["input", "blur"],
      },
      goToProfilePanel: () => {
        dialog.destroyAll();
        router.push({ name: "profile" });
      },
      handleUpdateAddressSelection,
    };
  },
});
</script>
